<template>
  <div
    :class="[
      'summary-checkout-btn',
      isShowFreeShippingTip ? 'checkout-btn-wrapper' : '',
    ]"
  >
    <div
      ref="checkoutActualBtnRef"
      :style="{ '--actual-btn-width': actualBtnWidth }"
      class="checkout-btn_container"
    >
      <div
        v-if="animationStripControl"
        :class="[GBCssRight ? 'checkout-btn-strip_ar' : 'checkout-btn-strip']"
      ></div>
      <!-- paypal ga 按钮 -这里使用v-show，避免使用v-if而导致sdk找不到dom -->
      <div
        v-show="paypalBtnShow"
        :id="paypalBtnID"
        style="width: 100%"
      ></div>
      <div
        v-show="paypalBnplBtnShow"
        :id="paypalBnplBtnID"
        style="width: 100%"
      ></div>
      <!-- applepay -->
      <ClientOnly>
        <ApplePayBtn
          v-if="!paypalBtnShow && !paypalBnplBtnShow && !paypalVenmoBtnShow && applePayBtnShow"
          ref="appleBtnRef"
          :propsStyle="{ width: '100%', height: '1.06rem' }"
          @handleBtnClick="createOrderByApplePay"
        />
      </ClientOnly>
      <!-- venmo按钮 -->
      <s-button
        v-if="paypalVenmoBtnShow && !applePayBtnShow"
        ref="venmoBtnRef"
        v-tpes:checkout
        :type="['primary', 'H80PX']"
        :loading="createOrderLoading"
        width="100%"
        class="venmo-btn j-checkout-btn"
        @click.native.stop="createOrder"        
      >
        <img
          v-show="!createOrderLoading"
          :src="IMG_LINK['blue-venmo-button']"
          class="venmo-btn-img"
        />
      </s-button>
      <!-- 普通按钮 -->
      <s-button
        v-if="!paypalBtnShow && !paypalBnplBtnShow && !paypalVenmoBtnShow && !applePayBtnShow"
        ref="btnRef"
        v-tpes:checkout
        class="j-checkout-btn"
        width="100%"
        :style="{ border: 'none', backgroundColor: normalBtnData.brandColor }"
        :type="['primary', 'H80PX']"
        :loading="createOrderLoading"
        @click.native.stop="createOrder"
      >
        <!-- 上文下图布局 -->
        <div
          v-if="nomalBtnLayoutType == 1"
          class="btn-first-layout"
        >
          <p
            class="btn-first-layout__text"
            v-html="normalBtnData.placeOrderText"
          >
          </p>
          <div
            v-if="isShowBNPLExclusiveStyle && normalBtnData.logo"
            class="btn-first-layout__logo"
          >
            <img
              :src="normalBtnData.logo"
              class="btn-first-layout__logo-img"
            />
          </div>
          <p
            v-else
            class="btn-first-layout__sub-text"
            v-html="normalBtnData.subText"
          ></p>
        </div>
        <!-- 左图右文布局 -->
        <div
          v-else-if="nomalBtnLayoutType == 2"
          class="btn-second-layout"
          :class="[isShowOrderBtnBenefit ? 'benefit-btn-layout' : '']"
        >
          <img
            v-if="normalBtnData.logo"
            class="btn-second-layout__logo"
            :src="normalBtnData.logo"
          />
          <p
            class="btn-second-layout__text"
            v-html="normalBtnData.placeOrderText"
          ></p>
        </div>
        <!-- 左文右图布局 -->
        <div
          v-else-if="nomalBtnLayoutType == 3"
          class="btn-third-layout"
          :class="[isShowOrderBtnBenefit ? 'benefit-btn-layout' : '']"
        >
          <p
            class="btn-third-layout__text"
            v-html="normalBtnData.placeOrderText"
          ></p>
          <img
            v-if="normalBtnData.logo"
            class="btn-third-layout__logo"
            :src="normalBtnData.logo"
          />
        </div>
        <!-- 默认 -->
        <div
          v-else
          :class="[isShowOrderBtnBenefit ? 'benefit-btn-layout' : '']"
        >
          <SAdapterText
            v-if="isUseSAdapterText"
            min-size="10"
            :text="placeOrderTxt"
          />
          <template v-else>
            {{ placeOrderTxt }}
          </template>
        </div>

        <!-- 按钮的利益点信息 -->
        <CheckoutBtnBenefit v-if="isShowOrderBtnBenefit" />
      </s-button>
    </div>
    <!-- 免邮提示 -->
    <div
      v-if="isShowFreeShippingTip"
      class="free-shipping-tip"
    >
      <ClientOnly>
        <labelsSwiper
          v-if="btnFreeQuickShipTips == 'quickshipFreeAll'"
          :config="labelSwiperConfig"
        />
      </ClientOnly>

      <template v-if="btnFreeQuickShipTips != 'quickshipFreeAll'">
        <Icon
          :name="quickShipFreeIcon"
          size="15px"
          color="#fff"
        />
        <span
        
          :class="['free-shipping-tip__text', isQuickShipBtnTips && 'italic-text']"
        >{{ btnFreeQuickShipTips }}</span>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import ClientOnly from 'vue-client-only'
import { AdapterText as SAdapterText } from '@shein/sui-mobile'
import { Icon } from '@shein-aidc/icon-vue2'
import { template, getQueryString } from '@shein/common-function'

import applePayConfig from 'public/src/pages/common/apple_pay/index.js'
import labelsSwiper from 'public/src/pages/checkout/page_tpls/components/atom/labelsSwiper.vue'
import ApplePayBtn from 'public/src/pages/components/ApplePayBtn.vue'
import CheckoutBtnBenefit from './CheckoutBtnBenefit.vue'
import { CO_BRAND_CARD_LOGO, CO_BRAND_LEVEL_LIST } from '../../page_tpls/token_pay/constant'
import { exposeScenesAbtAggregator } from 'public/src/pages/checkout/tools/EventAggregator.js'

const normalBtnLayoutType = {
  TOP_TEXT_BOTTOM_IMAGE: 1, // 1是上文下图布局
  LEFT_IMAGE_RIGHT_TEXT: 2, // 2是左图右文布局
  LEFT_TEXT_RIGHT_IMAGE: 3, // 3是左文右图布局
  DEFAULT: -1 // 默认兜底
}

// 支付方式类型
const placeBtnPayType = {
  CARD_TYPE: 'CARDTYPE', // 卡支付类型	
  BNPL_TYPE: 'BNPLTYPE', // bnpl有利诱点的支付方式类型	
  OTHER_TYPE: 'OTHERTYPE', // 其余支付方式
}

export default {
  name: 'SummaryCheckoutBtn',
  components: {
    ClientOnly,
    SAdapterText,
    Icon,
    labelsSwiper,
    ApplePayBtn,
    CheckoutBtnBenefit
  },
  props: {
    paypalBtnShow: {
      type: Boolean,
      default: false 
    },
    paypalBnplBtnShow: {
      type: Boolean,
      default: false
    },
    paypalVenmoBtnShow: {
      type: Boolean,
      default: false
    },
    paymentMethodsProps: {
      type: Object,
      default: () => {}
    },
    govTaxPrice: {
      type: [Number, String],
      default: 0
    },
    isDrawerBtn: {
      type: Boolean,
      default: false
    },
    isShowFreePriceTip: {
      type: Boolean,
      default: true
    },
    paypalButtonId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isClient: false,
      isUseSAdapterText: false,
      actualBtnWidth: 'auto', // 按钮的实际宽度
      isCheckoutBtnWidthChange: false,
      venmoBtnRef: null,
      btnRef: null,
      animationStripControl: false,
      fristAnimationStrip: 0,
      normalBtnData: {
        placeOrderText: '', // 按钮文案
        logo: '', // 支付方式的logo
        brandColor: '', // 品牌色（按钮用于设置背景色，用在BNPL的ABT实验为2的时候）
        subText: '', // 按钮副文案（用在BNPL的ABT实验为2的时候）
      },
      nomalBtnLayoutType: normalBtnLayoutType.DEFAULT, // 普通按钮的布局样式 1是上文下图布局、2是左图右文布局、3是左文右图布局
    }
  },
  computed: {
    ...mapGetters('checkout', ['isFreePrice', 'quickShipCheckoutStatus', 'isSelectTokenPay', 'isSelectInstallmentTokenPay', 'GBCssRight', 'orderBtnBenefitInfo', 'orderBtnActualBenefitInfo', 'defaultCheckoutButtonText']),
    ...mapState('checkout', [
      'language',
      'createOrderLoading',
      'checkoutAbtInfo',
      'checkout',
      'status',
      'price',
      'isOrderTotalOut',
      'defaultSelectTokenPay',
      'installmentSelectBin',
      'defaultShipping',
      'addresslist',
      'locals',
      'isUseNewCard'
    ]),
    IMG_LINK() {
      return this.locals?.IMG_LINK ?? gbCommonInfo?.IMG_LINK
    },
    IS_RW() {
      return this.locals?.IS_RW ?? gbCommonInfo?.IS_RW
    },
    getPlaceOrderButtonPayStatusByAbt() {
      return this.checkoutAbtInfo?.PlaceOrderButtonPay?.param?.PlaceOrderButtonPayStatus == 1
    },
    getBnplPlaceOrderStatsByAbt() {
      return Number(this.checkoutAbtInfo?.bnplPlaceOrder?.param?.bnplPlaceOrderStats)
    },
    quickShipFreeIcon(){
      return this.isQuickShipBtnTips ? 'sui_icon_qucikship_flat_24px' : 'sui_icon_free_shipping_12px'
    },
    labelSwiperConfig(){ 
      return [{
        text: this.quickShipCheckoutStatus.quickShipLabelText,
        icon: 'sui_icon_qucikship_flat_24px',
        quickship: true
      },
      {
        text: this.language.SHEIN_KEY_PWA_23851,
        icon: 'sui_icon_free_shipping_12px',
        freeShoping: true
      }
      ]
    },
    totalPrice() {
      return this.price?.grandTotalPrice?.amountWithSymbol
    },
    // 是否展示免邮的提示标签
    isShowFreeShippingTip() {
      return ((!this.IS_RW && this.isFreePrice) || this.isQuickShipBtnTips) && this.isShowFreePriceTip
    },
    currentPayInPayListInfo() {
      return this.checkout?.results?.paymentMethods?.filter(i => this.status?.cardPay === i.code && i.enabled) || []
    },
    filterBNPLExclusiveData() {
      if (!this.status?.cardPay) {
        return []
      }
      return this.checkout?.BNPL_PAY_BTN?.filter(item => item.payCode === this.status?.cardPay)
    },
    getFrontShowList() {
      return this.checkout.installmentInfo?.front_show_list || []
    },
    // 获取分期的金额，如果分期金额没有就是之前的样式
    getInstallMentData() {
      if (!this.status?.cardPay) {
        return ''
      }
      const installmentData = this.getFrontShowList?.find(item => item.payment_code === this.status?.cardPay)
      const installmentAmount =  installmentData?.place_order_period_price?.amountWithSymbol
      return installmentAmount || ''
    },
    // 当前支付方式是BNPL，并且需要更改按钮的样式
    getPayBNPLNewBtnLayoutStyle() {
      const paymentCode = this.isClient && getQueryString({ key: 'paymentCode' })
  
      if(this.IS_RW || (paymentCode && !this.status?.cardPay) || !this.getInstallMentData) {
        return -1
      }

      return !!this.currentPayInPayListInfo?.length ? this.getBnplPlaceOrderStatsByAbt : -1
    },
    // 当前支付方式是BNPL，并且按钮需要变成专属样式（上下布局）
    isShowBNPLExclusiveStyle() {
      return this.getPayBNPLNewBtnLayoutStyle === 2 && this.filterBNPLExclusiveData?.length > 0
    },
    // 当前支付方式是BNPL，并且按钮需要变成专属样式（左右布局）
    isShowBNPLOtherStyle() {
      const limitArr = ['PayPal-GApaypal', 'PayPal-Venmo', 'PayPal-bnpl', 'routepay-card', 'routepay-cardinstallment'].concat(this.applePayMethods) // 兜底，防止配置错误
      return this.getPayBNPLNewBtnLayoutStyle == 1 && !limitArr.includes(this.status?.cardPay)
    },
    // 卡路由 卡分期，需要监听这两个参数是否变化
    cardInfo() {
      const cardTokenList = this.status?.cardPay == 'routepay-card' && this.checkout?.results?.paymentMethods?.find(i => 'routepay-card' === i.code && i.enabled)?.card_token_list
      const cardInstallTokenList = this.status?.cardPay == 'routepay-cardinstallment' && this.checkout?.results?.paymentMethods?.find(i => 'routepay-cardinstallment' === i.code && i.enabled)?.card_token_list
      return [this.defaultSelectTokenPay?.id, this.installmentSelectBin, cardTokenList, cardInstallTokenList]
    },
    // 当前选中的卡支付信息
    selectCardInfo(){
      if (['routepay-card'].includes(this.status?.cardPay)) {
        const currentCardInfo = this.checkout?.results?.paymentMethods?.find(i => 'routepay-card' === i.code && i.enabled)
        const info = currentCardInfo?.card_token_list?.find(f => f?.id == this.defaultSelectTokenPay?.id)
        return currentCardInfo?.shieldAddCard == 0 && info
      }
      return {}
    },
    // 当前选中的卡分期信息
    selectCardInstallmentInfo(){
      if (['routepay-cardinstallment'].includes(this.status?.cardPay)) {
        const currentCardInfo = this.checkout?.results?.paymentMethods?.find(i => 'routepay-cardinstallment' === i.code)
        const info = currentCardInfo?.card_token_list?.find(f => f?.card_bin == this.installmentSelectBin)
        return info
      }
      return {}
    },
    // 卡支付/卡分期
    isShowRouterCardPay() {
      // const { webshowTokenFront } = this.checkout?.abtInfo
      const isShowTokenPaymentByAbt = true  // 11.04已推全
      const routerCardVisible = isShowTokenPaymentByAbt && !!Object.keys(this.selectCardInfo || {}).length && this.selectCardInfo
      const routerCardInstalment = this.isSelectInstallmentTokenPay && !!Object.keys(this.selectCardInstallmentInfo || {}).length && this.selectCardInstallmentInfo
      return this.getPlaceOrderButtonPayStatusByAbt && (routerCardVisible || routerCardInstalment)
    },
    isSpecialCardPay() {
      if (!this.status?.cardPay) {
        return false
      }
      const limitArr = ['PayPal-GApaypal', 'PayPal-Venmo', 'routepay-card', 'routepay-cardinstallment'].concat(this.applePayMethods)
      return !limitArr.includes(this.status?.cardPay)
    },
    isSatisfyBnplHasLure() {
      return this.getPlaceOrderButtonPayStatusByAbt && this.isSpecialCardPay && !!this.getInstallMentData 
    },
    isSatisfyBnplHasNotLure() {
      return this.getPlaceOrderButtonPayStatusByAbt && this.isSpecialCardPay && !this.getInstallMentData 
    },
    // 下单按钮默认文案
    placeOrderTxt () {
      let txt = this.defaultCheckoutButtonText

      if (this.getPlaceOrderButtonPayStatusByAbt && ['routepay-card', 'routepay-cardinstallment'].includes(this.status?.cardPay)) {
        return (txt || '').toUpperCase()
      }
      if (this.status?.cardPay !== 'routepay-card') {
        // abt 全推 placeOrderButtonUpgradeStatus写死 等于1 不受abt 控制
        txt = !this.IS_RW ? this.language?.SHEIN_KEY_PWA_21233 : this.language?.SHEIN_KEY_PWA_24002
      }
      
      return (txt || '').toUpperCase()
    },
    // 按钮利益点展示条件
    isShowOrderBtnBenefit() {
      if (!!this.orderBtnActualBenefitInfo.length) {
        return this.nomalBtnLayoutType == normalBtnLayoutType.DEFAULT || 
          (this.nomalBtnLayoutType == normalBtnLayoutType.LEFT_TEXT_RIGHT_IMAGE && !this.filterBNPLExclusiveData?.length) || 
          (this.nomalBtnLayoutType == normalBtnLayoutType.LEFT_IMAGE_RIGHT_TEXT && this.isShowRouterCardPay)
      }
      return false
    },
    isQuickShipBtnTips(){
      const { isAllCartsQuickShip, PlaceOrderQuickshipWord } = this.quickShipCheckoutStatus
      return isAllCartsQuickShip && PlaceOrderQuickshipWord == 1
    },
    btnFreeQuickShipTips(){
    
      return this.isFreePrice && this.isQuickShipBtnTips
        ? 'quickshipFreeAll'
        : this.isQuickShipBtnTips ?  this.quickShipCheckoutStatus?.quickShipLabelText : this.language.SHEIN_KEY_PWA_23851
    },
    paypalBtnID() {
      if (this.paypalButtonId) return this.paypalButtonId
      return this.isDrawerBtn ? 'drawer-paypal-vault-button' : 'paypal-vault-button'
    },
    paypalBnplBtnID() {
      if (this.paypalBnplButtonId) return this.paypalBnplButtonId
      return this.isDrawerBtn ? 'drawer-paypal-bnpl-button' : 'paypal-bnpl-button'
    },
    applePayBtnShow() {
      const apolloInfo = this.applePayMethods || []
      const applepayDropoffOptimizationAbt = this.checkout?.abtInfo?.ApplepayDropoffOptimization?.p?.ApplepayButtonShow === 'Show'
      if(apolloInfo?.includes(this.status?.cardPay) && applepayDropoffOptimizationAbt && this.isClient){
        return true
      }
      return false
    },
    // paypal签约状态 0 是没有签约 1是签约
    paypalSignStatus() {
      return 'PayPal-GApaypal' == this.status?.cardPay && this.paymentMethodsProps?.signupPayVault?.[this.status?.cardPay]?.type == 0
    },
    getOrderBtnBenefitData() {
      return Object.keys(this.orderBtnBenefitInfo)?.filter(i => this.orderBtnBenefitInfo[i]).join(',')
    },
    applePayMethods () {
      if (this.checkout?.APPLE_PAY_CODE_LIST?.length) return this.checkout.APPLE_PAY_CODE_LIST
      return applePayConfig?.applePayChannel || []
    },
    // 按钮的支付类型
    placeOrderPayType() {
      const payTypeStatus = this.checkoutAbtInfo?.PlaceOrderButtonPayStyle?.param?.PlaceOrderButtonPayStyleStatus
      return payTypeStatus.split(',').map(_ => _.toUpperCase())
    }
  },
  watch: {
    govTaxPrice(newVal, oldVal){
      if (newVal !== oldVal) {
        this.handleCheckoutBtnWidth()
      }
    },
    totalPrice(newVal, oldVal){
      if (newVal !== oldVal) {
        this.handleCheckoutBtnWidth()
      }
    },
    isShowFreeShippingTip: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('hanldFreeShippingTipShow', newVal)
        }
      },
      immediate: true
    },
    isOrderTotalOut(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.handleCheckoutBtnAnimation(newVal)
      }
    },
    'status.cardPay'(newVal, oldVal){
      if (newVal != oldVal && newVal) {
        this.handleNormalBtnInfo()
      }
      this.animationStripControlFn()
    },
    defaultShipping(){
      this.animationStripControlFn()
    },
    cardInfo(newVal, oldVal) {
      if (newVal != oldVal && newVal) {
        this.handleNormalBtnInfo()
      }
    },
    paypalSignStatus() {
      this.handleNormalBtnInfo()
    },
    isUseNewCard() {
      this.handleNormalBtnInfo()
    },
    getOrderBtnBenefitData(newVal, oldVal) {
      if (newVal != oldVal && newVal) {
        this.handleAnalyzeBenefit()
      }
    },
    getFrontShowList(newVal, oldVal) {
      if (newVal && newVal?.length > 0 && JSON.stringify(newVal) != JSON.stringify(oldVal)) {
        if (newVal.find(item => item.payment_code === this.status?.cardPay)) {
          this.handleNormalBtnInfo()
        }
      }
    }
  },
  mounted() {
    this.isClient = true
    if (!this.isOrderTotalOut) {
      this.isUseSAdapterText = true
    }
    this.handleNormalBtnInfo()
    this.handleCheckoutBtnWidth()
    this.animationStripControlFn()
    this.handleAnalyzeBenefit()
  },
  methods: {
    ...mapMutations('checkout', ['assignState']),
    // 注意！！！此处不要有任何return事件，统一收口至最尾节点处理！！！下单按钮点击事件需要上报埋点及监控！！！
    createOrder() {
      sessionStorage.setItem('navtrack_to', 'page_payment')
      sessionStorage.setItem('navtrack_point', Date.now())
      this.$emit('createOrder')
    },
    handleBNPLPlaceText({ installmentColor,  wordColor }) {
      // BNPL上文下图布局
      if (this.getInstallMentData && normalBtnLayoutType.TOP_TEXT_BOTTOM_IMAGE === this.nomalBtnLayoutType) {
        return `<span style="color: ${wordColor}">${template(`<span style="color: ${installmentColor}">${this.getInstallMentData}</span>`, this.language.SHEIN_KEY_PWA_30047)}</span>`
      }

      // BNPL左图右文布局
      if (this.getInstallMentData && normalBtnLayoutType.LEFT_IMAGE_RIGHT_TEXT === this.nomalBtnLayoutType) {
        if (this.isSatisfyBnplHasLure && !this.placeOrderPayType.includes(placeBtnPayType.BNPL_TYPE)) {
          return this.language.SHEIN_KEY_PWA_21233?.toUpperCase() // CONTINUE
        }
        return `<span style="color: ${wordColor}">${template(`<span style="color: ${installmentColor}">${this.getInstallMentData}</span>`, this.language.SHEIN_KEY_PWA_30047)}</span>` // pay ${x} today
      }

      // 其余支付方式
      if (this.placeOrderPayType.includes(placeBtnPayType.OTHER_TYPE)) {
        // SHEIN_KEY_PWA_34248 'Continue with {0}' SHEIN_KEY_PWA_31565 'Pay with'
        return this.checkoutAbtInfo?.ButtonPlaceOrderUpgradeNew?.param?.ButtonPlaceOrderUpgradeNewStatus == 1 ? this.language.SHEIN_KEY_PWA_34248 : this.language.SHEIN_KEY_PWA_31565
      }
      return this.language.SHEIN_KEY_PWA_21233?.toUpperCase() // CONTINUE
    },
    // 获取当前非paypal、venmo等常规按钮的信息
    handleNormalBtnInfo() {
      if (this.paypalSignStatus || ['PayPal-Venmo'].includes(this.status?.cardPay) || (this.applePayBtnShow && this.applePayMethods.includes(this.status?.cardPay))) {
        return
      }
      const defaultBtnInfo = {
        placeOrderText: this.placeOrderTxt, // 按钮文案
        logo: '', // 支付方式的logo
        brandColor: '', // 品牌色（按钮用于设置背景色，用在BNPL的ABT实验为2的时候）
        subText: '', // 按钮副文案（用在BNPL的ABT实验为2的时候）
      }
      // 卡支付/卡分期
      if (this.isShowRouterCardPay) {
        if (this.isSelectInstallmentTokenPay && this.isUseNewCard) {
          this.nomalBtnLayoutType = normalBtnLayoutType.DEFAULT
          this.normalBtnData = defaultBtnInfo
          return defaultBtnInfo
        }
        const { web_logo, card_no, webLogo, cardNo } = this.isShowRouterCardPay
        const placeOrderText = this.placeOrderPayType.includes(placeBtnPayType.CARD_TYPE) ? 
          template(card_no || cardNo, this.language.SHEIN_KEY_PWA_32025) : 
          this.language.SHEIN_KEY_PWA_15135?.toUpperCase()

        this.nomalBtnLayoutType = typeof window == 'undefined' ? normalBtnLayoutType.DEFAULT : normalBtnLayoutType.LEFT_IMAGE_RIGHT_TEXT
        const isCoBranedCard = CO_BRAND_LEVEL_LIST.includes(this.isShowRouterCardPay?.co_brand_level)
        this.normalBtnData =  {
          ...defaultBtnInfo,
          logo: isCoBranedCard ? CO_BRAND_CARD_LOGO : (web_logo || webLogo),
          placeOrderText,
        }
        return
      }

      if (this.isShowBNPLExclusiveStyle) {
        const { installmentColor, wordColor, brandLogo, brandColor } = this.filterBNPLExclusiveData[0] || {}
        const title = this.currentPayInPayListInfo?.[0]?.title || ''
        this.nomalBtnLayoutType = normalBtnLayoutType.TOP_TEXT_BOTTOM_IMAGE
        this.normalBtnData = {
          ...defaultBtnInfo,
          placeOrderText: this.handleBNPLPlaceText({ installmentColor, wordColor }),
          subText: `${this.language.SHEIN_KEY_PWA_30434} ${title}`,
          logo: brandLogo,
          brandColor,
        }
        return
      }
      
      if (this.isSatisfyBnplHasLure || this.isSatisfyBnplHasNotLure || this.isShowBNPLOtherStyle) {
        if (this.isSatisfyBnplHasNotLure) {
          this.nomalBtnLayoutType = normalBtnLayoutType.LEFT_TEXT_RIGHT_IMAGE
        } else {
          this.nomalBtnLayoutType = typeof window == 'undefined' ? normalBtnLayoutType.DEFAULT : normalBtnLayoutType.LEFT_IMAGE_RIGHT_TEXT
        }
        
        this.normalBtnData = {
          ...defaultBtnInfo,
          placeOrderText: this.handleBNPLPlaceText({ installmentColor: '#FA6338', wordColor: '#fff' }),
          logo: this.currentPayInPayListInfo?.[0]?.logo_url || '',
        }
        return
      }
      this.nomalBtnLayoutType = normalBtnLayoutType.DEFAULT
      this.normalBtnData = defaultBtnInfo
    },
    createOrderByApplePay() {
      sessionStorage.setItem('navtrack_to', 'page_payment')
      sessionStorage.setItem('navtrack_point', Date.now())
      appEventCenter.$emit('applepay-create-order')
    },
    handleCheckoutBtnWidth() {
      if (this.isClient && this.$refs?.checkoutActualBtnRef) {
        this.actualBtnWidth = this.$refs?.checkoutActualBtnRef?.offsetWidth + 'px'
      }
    },
    handleCheckoutBtnAnimation(val) {
      if (!this.isClient) {
        return
      }
      if (val) {
        this.$refs.checkoutActualBtnRef?.classList?.remove('checkout-btn-lessen')
        this.$refs.checkoutActualBtnRef?.classList?.add('checkout-btn-overspread')
        this.isCheckoutBtnWidthChange = true
        this.isUseSAdapterText = false
      } else if (this.isCheckoutBtnWidthChange) {
        this.$refs.checkoutActualBtnRef?.classList?.remove('checkout-btn-overspread')
        this.$refs.checkoutActualBtnRef?.classList?.add('checkout-btn-lessen')
        
        setTimeout(()=>{ this.isUseSAdapterText = true }, 500)
      }
    },
    handlerClick() {
      if (this.paypalVenmoBtnShow && !this.applePayBtnShow) {
        this.$refs?.venmoBtnRef?.handleClick()
        return
      }

      if (!this.paypalBtnShow && !this.paypalBnplBtnShow && !this.paypalVenmoBtnShow && !this.applePayBtnShow) {
        this.$refs?.btnRef?.handleClick()
        return
      }
    },
    animationStripControlFn(){
      if(typeof window == 'undefined') return false
      
      this.$nextTick(() => {
        if(this.fristAnimationStrip == 1 || !this.status?.cardPay || !this.defaultShipping?.length || !this.addresslist?.length) return
        this.animationStripControl = true
        this.fristAnimationStrip = 1
        setTimeout(() => {
          this.animationStripControl = false
        }, 900)
      })
    },
    handleAnalyzeBenefit() {
      if (this.getOrderBtnBenefitData) {
        exposeScenesAbtAggregator.addEvent({
          daId: '1-11-1-172',
          extraData: {
            scenes: 'innerPlaceorderBenefits',
            inner_benefits_type: this.getOrderBtnBenefitData
          }
        })
      }
    },
  }
}
</script>

<style lang="less">
// 新版本的按钮样式
.summary-checkout-btn {
  position: relative;
  flex: 1;
  min-width: 0; // 设置flex是1的时候，消除子元素超过父元素的宽度
  height: 80/75rem;
  .S-button__H80PX {
    padding: 0 24/75rem;
  }
}
.checkout-btn-wrapper {
  position: relative;
  .free-shipping-tip {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -30/75rem;
    right: -8/75rem;
    max-width: calc(~"100% + 0.106rem"); // 0.016rem => 定位right的值，即8/75rem
    padding: 2/75rem 8/75rem;
    background: var(---sui_color_success, #198055);
    z-index: 100; // 这里主要是解决PayPal按钮的遮蔽问题
    &__text {
      max-width: calc(~"100% + 0.466rem"); // 0.466rem => 定位right的值 + 所有的横向间距 + icon的宽度，获得文本的最大宽度
      height: 26/75rem;
      padding: 0 2/75rem;
      margin-left: 8/75rem;
      color: #FFF;
      font-weight: 500;
      .font-dpr(20px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .italic-text{
      font-style: italic;
    }
  }
  &::after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    top: 0%;
    right: 0%;
    transform: translate(100%, 50%);
    border: 4.5/75rem solid;
    border-color: #16533A transparent transparent #16533A;
  }
}
// 按钮横向铺满整屏
.checkout-btn-overspread {
  /* stylelint-disable-next-line declaration-no-important */
  position: absolute !important;
  right: 0;
  top: 0;
  animation: overspreadWholeScreen 0.5s ease forwards;
}
@keyframes overspreadWholeScreen {
  0% {
    width: var(--actual-btn-width);
  }
  100% {
    width: 710/75rem;
  }
}
// 按钮从横向铺满整屏返回到原来的样子
.checkout-btn-lessen {
  /* stylelint-disable-next-line declaration-no-important */
  position: absolute !important;
  right: 0;
  top: 0;
  animation: zoomToRight 0.5s ease forwards;
}
@keyframes zoomToRight {
  0% {
    width: 710/75rem;
  }
  100% {
    width: 100%;
  }
}

// 擦亮动画
@keyframes strip-move_left {
  0% {
    left: -2rem;
  }
  100% {
    left: 100%
  }
}
@keyframes strip-move_right {
  0% {
    right: -2rem;
  }
  100% {
    right: 100%
  }
}

.checkout-btn_container{
  position: relative;
  overflow: hidden;
  // 扫光动画
  .checkout-btn-strip{
    height: 100%;
    position: absolute;
    top: 0;
    left: -2rem;
    z-index: 2;
    background: url('https://img.ltwebstatic.com/images3_ccc/2024/06/28/2a/17195441661b972a9333a54fb4ca3c83c2efb55f59.png') no-repeat top/center;
    width: 100/37.5rem;
    animation: strip-move_left 1s 1;
  }
  .checkout-btn-strip_ar{
    height: 100%;
    position: absolute;
    top: 0;
    right: -2rem;
    z-index: 2;
    background: url('https://img.ltwebstatic.com/images3_ccc/2024/06/28/2a/17195441661b972a9333a54fb4ca3c83c2efb55f59.png') no-repeat top/center;
    width: 100/37.5rem;
    animation: strip-move_right 1s 1;
    transform: rotate(180deg);

  }
}
.venmo-btn{
  background-color: #3D95CE;
  border-radius: 6/75rem;
  border-color: transparent;
  .venmo-btn-img{
    height: 26/75rem;
  }
}

.btn-first-layout{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding: 16/75rem 0 12/75rem;
  &__text{
    width: 100%;
    height: 28/75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 700;
    font-size: 12px;
    line-height: 1.2;
  }
  &__logo{
    display: flex;
    align-items: center;
    margin-top: 4/75rem;
  }
  &__logo-img {
    height: 20/75rem;
  }
  &__sub-text {
    width: 100%;
    height: 24/75rem;
    margin-top: 4/75rem;
    font-size: 10px;
    font-weight: 700;
    line-height: normal;
    color: var(---sui_color_white, #FFF);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.btn-second-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20/75rem 0;
  &__logo{
    width: 60/75rem;
    height: 100%;
    margin-right: 8/75rem;
    background-color: #FFF;
    border-radius: 4/75rem;
  }
  &__text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.2;
  }
}
.btn-third-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20/75rem 24/75rem;
  &__logo{
    width: 60/75rem;
    height: 40/75rem;
    margin-left: 8/75rem;
    border-radius: 4/75rem;
    background-color: #FFF;
  }
  &__text{
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.2;
  }
}

.benefit-btn-layout {
  height: 34/75rem;
  padding: 0;
  line-height: normal;
}
</style>
